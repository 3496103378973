<template>
  <div class="inner-container">
    <section class="banner-container-nd">
      <div class="banner"></div>
      <div class="banner-content">
        <div class="cards-container">
          <v-card
            v-for="(card, index) in cards"
            :key="index"
            class="hover-card"
            @click="handleCardClick()"
          >
            <div class="text-center title-color my-3">
              {{ card.title }}
            </div>
          </v-card>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
  import { mapGetters,mapActions } from "vuex";
  
  export default {
    data() {
      return {
        data: {
    "brouillon": 1,
    "type_devis": "",
    "entreprise_id": "1",
    "modele_id": "14",
   
  },
  selectedCards: [],
        cards: [
          { title: "Libre", route: "/devis/devisTypes" },

        ],
      };
    },
    components: {},
    computed: {
    ...mapGetters(["getTabs", "getOnlineUser", "getcreationEntreprise", "getdevisType"]),
    isSuperAdmin() {
      return this.getOnlineUser.role === "user.super-admin";
    },
    isEntrepriseAdmin() {
      return this.getOnlineUser.role === "user.entreprise-admin";
    },
    isCommercial() {
      return this.getOnlineUser.role === "user.commercial-entreprise";
    },
    isAssistant() {
      return this.getOnlineUser.role === "user.assistant";
    },
  },
    methods: { 
      ...mapActions(["store_devis", "updateCreationEntreprise", "devisTypeAdd","addGroupe"]),
      async createDevis(typeDevis) {
      try {
        this.data.type_devis = typeDevis;
        this.data.entreprise_id = this.getOnlineUser.entreprise.id;
        const response = await this.store_devis(this.data);
        if (this.selectedCards.length > 0) {
          const groupes = this.selectedCards.map(() => ({
  document_id: response.data?.id,
  type_group: 'Libre',
}));

for (const groupe of groupes) {
  await this.addGroupe(groupe); 
}
        } else {
          const groupes = 
            {
              document_id:response.data?.id,
              type_group:typeDevis,
            };
          await this.addGroupe(groupes)
        }

      
        this.id = response.data?.devis?.id;
    
    this.$router.push(`/devis/manuel/${this.id}`);
  
      } catch (error) {
        console.error("Erreur lors de la création du devis :", error);
      }
    }, 
      handleCardClick(card) {
      this.createDevis(!this.selectedType ? 'Libre' : card);
      this.updateCreationEntreprise(true);
    },
    },
  };
  </script>
  
  <style lang="scss" scoped>
    .inner-container {
      padding: 0 !important;
      width: 100%;
      margin-top: 50px;
    }
     @media only screen and (min-width: 810px) { 
    .banner-container-nd {
      position: relative;
      height: calc(100vh - 50px); 
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      width: 100%; 
    }
     } 
    
    .banner-content {
      @media only screen and (max-width: 900px) {
     
        padding: 8px;
        width: 100%;
      }
      width: 100%;
      text-align: center;
    
      .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // gap: 20px; 
      }
    
      .v-card {
        flex: 1 1 calc(25% - 20px); 
        max-width: calc(25% - 20px); 
        margin: 10px; 
        border-radius: 8px; 
        background-color: #24316d;
        font-size: 16px; 
        font-weight: 600;
        padding: 20px;
      }
    
      @media only screen and (max-width: 810px) {
         .banner-container-nd {
      position: relative;
      height: calc(140vh -50px); 
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      width: 100%; 
    } 
        .v-card {
          flex: 1 1 calc(50% - 20px); 
          max-width: calc(50% - 20px); 
        }
      }
      @media only screen and (max-width: 620px) {
         .banner-container-nd {
      position: relative;
      height: calc(200vh); 
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      width: 100%; 
    } 
        .v-card {
          flex: 1 1 calc(100% - 20px);
          max-width: calc(100% - 20px); 
        }
      }
    }
    
    .hover-card {
      color: white;
      transition: box-shadow 0.3s ease;
    }
    
    .hover-card:hover {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2); 
    }
    
    .card-link {
      background-color: #24316d;
      text-decoration: none;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }
    .title-color{
      align-items:center;
      color:white;
    }
    </style>
  